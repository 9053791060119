.decline-screen {
  width: 100%;
  max-width: 45rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  margin: auto; }
  .decline-screen .logo-container {
    width: 55%; }
    .decline-screen .logo-container img {
      width: 100%; }
  .decline-screen .message {
    font-size: 2.4rem;
    line-height: 3.6rem;
    padding: 5rem 0; }
  .decline-screen .dismiss-button {
    background-color: #531362;
    color: #fff;
    border-radius: 4px;
    width: 20rem;
    height: 33px;
    padding: initial; }
