.confirmation-modal {
  color: #444444;
  font-size: 1.4rem; }
  .confirmation-modal .modal-content {
    padding: 0 1.5rem; }
  .confirmation-modal .modal-header {
    padding: 1.5rem 1rem 0.5rem;
    font-size: 2rem;
    border: none; }
    .confirmation-modal .modal-header .modal-title {
      font-size: 3.2rem;
      font-weight: 600;
      padding-top: 3rem;
      width: 100%;
      display: flex;
      justify-content: center;
      color: #71768b; }
    .confirmation-modal .modal-header .close {
      font-size: 2rem;
      font-weight: 400;
      color: #ff3c9e;
      padding-right: 0; }
  .confirmation-modal .modal-body {
    padding: 2rem 0.5rem;
    font-weight: 400;
    font-size: 1.4rem; }
    .confirmation-modal .modal-body p {
      text-align: center; }
  .confirmation-modal .modal-footer {
    padding: 1.5rem 0;
    border-top: none;
    justify-content: center; }
    .confirmation-modal .modal-footer .btn {
      width: 16rem;
      height: 4rem;
      border-radius: 4px;
      font-size: 1.4rem; }
    .confirmation-modal .modal-footer .btn-primary:disabled {
      cursor: initial; }
    .confirmation-modal .modal-footer .btn-secondary {
      margin-right: 0.5rem; }
