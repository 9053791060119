@font-face {
  font-family: 'Open Sans';
  src: url("./../assets/fonts/Open-Sans/OpenSans-Regular-webfont.eot");
  src: url("./../assets/fonts/Open-Sans/OpenSans-Regular-webfont.eot?#iefix") format("embedded-opentype"), url("./../assets/fonts/Open-Sans/OpenSans-Regular-webfont.woff") format("woff"), url("./../assets/fonts/Open-Sans/OpenSans-Regular-webfont.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url("./../assets/fonts/Open-Sans/OpenSans-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'Open Sans';
  src: url("./../assets/fonts/Open-Sans/OpenSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url("./../assets/fonts/Open-Sans/OpenSans-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'Open Sans';
  src: url("./../assets/fonts/Open-Sans/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url("./../assets/fonts/Open-Sans/OpenSans-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: 'Open Sans';
  src: url("./../assets/fonts/Open-Sans/OpenSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url("./../assets/fonts/Open-Sans/OpenSans-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: 'Open Sans';
  src: url("./../assets/fonts/Open-Sans/OpenSans-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url("./../assets/fonts/Open-Sans/OpenSans-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic; }

@font-face {
  font-family: 'Lato';
  src: url("./../assets/fonts/Lato/Lato-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'Lato';
  src: url("./../assets/fonts/Lato/Lato-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: 'Lato';
  src: url("./../assets/fonts/Lato/Lato-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Lato';
  src: url("./../assets/fonts/Lato/Lato-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'Lato';
  src: url("./../assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Lato';
  src: url("./../assets/fonts/Lato/Lato-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'Lato';
  src: url("./../assets/fonts/Lato/Lato-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Lato';
  src: url("./../assets/fonts/Lato/Lato-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: 'Lato';
  src: url("./../assets/fonts/Lato/Lato-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Lato';
  src: url("./../assets/fonts/Lato/Lato-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic; }

@font-face {
  font-family: 'WorkSans';
  src: url("./../assets/fonts/WorkSans/WorkSans-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'WorkSans';
  src: url("./../assets/fonts/WorkSans/WorkSans-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: 'WorkSans';
  src: url("./../assets/fonts/WorkSans/WorkSans-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: 'WorkSans';
  src: url("./../assets/fonts/WorkSans/WorkSans-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic; }

@font-face {
  font-family: 'WorkSans';
  src: url("./../assets/fonts/WorkSans/WorkSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'WorkSans';
  src: url("./../assets/fonts/WorkSans/WorkSans-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'WorkSans';
  src: url("./../assets/fonts/WorkSans/WorkSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'WorkSans';
  src: url("./../assets/fonts/WorkSans/WorkSans-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'WorkSans';
  src: url("./../assets/fonts/WorkSans/WorkSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'WorkSans';
  src: url("./../assets/fonts/WorkSans/WorkSans-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: 'WorkSans';
  src: url("./../assets/fonts/WorkSans/WorkSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'WorkSans';
  src: url("./../assets/fonts/WorkSans/WorkSans-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: 'WorkSans';
  src: url("./../assets/fonts/WorkSans/WorkSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'WorkSans';
  src: url("./../assets/fonts/WorkSans/WorkSans-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: 'WorkSans';
  src: url("./../assets/fonts/WorkSans/WorkSans-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'WorkSans';
  src: url("./../assets/fonts/WorkSans/WorkSans-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic; }

@font-face {
  font-family: 'WorkSans';
  src: url("./../assets/fonts/WorkSans/WorkSans-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'WorkSans';
  src: url("./../assets/fonts/WorkSans/WorkSans-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic; }

@font-face {
  font-family: 'Comfortaa';
  src: url("./../assets/fonts/Comfortaa/Comfortaa-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Comfortaa';
  src: url("./../assets/fonts/Comfortaa/Comfortaa-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Comfortaa';
  src: url("./../assets/fonts/Comfortaa/Comfortaa-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Comfortaa';
  src: url("./../assets/fonts/Comfortaa/Comfortaa-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Comfortaa';
  src: url("./../assets/fonts/Comfortaa/Comfortaa-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

* {
  box-sizing: border-box; }

html {
  font-size: 62.5%;
  width: 100%;
  height: 100%; }

html.keyboard-shown {
  height: 100%; }

body,
body > #root,
#root .page {
  width: 100%;
  height: 100%; }

#root {
  position: relative;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom); }

body {
  font-family: 'Open Sans', Arial;
  font-size: 12px;
  margin: 0;
  padding: 0;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  height: 100%;
  color: #2f385c;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none; }

.App {
  height: 100%;
  width: 100vw;
  margin: auto;
  background-color: #dad8da;
  overflow-y: auto; }

.flex-one {
  flex: 1; }

.flex-two {
  flex: 2; }

button:focus {
  outline: none; }

.btn-primary {
  border-radius: 0;
  background-color: #00b4c3 !important;
  border-color: #00b4c3 !important; }

.btn-secondary {
  border-radius: 0;
  background-color: #ff3c9e !important;
  border-color: #ff3c9e !important; }

.vertical-ruler {
  width: 1px;
  background-color: #dad8da; }

.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  padding: 0; }

.row {
  margin: 0; }

.btn:focus {
  box-shadow: none !important; }

.btn-success,
.show > .btn-success.dropdown-toggle {
  color: transparent !important;
  background-color: inherit !important;
  border-color: transparent !important; }
  .btn-success:focus,
  .show > .btn-success.dropdown-toggle:focus {
    box-shadow: none; }

.button {
  font-family: 'Avenir LT', arial, sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  background-color: #4811a3;
  color: #fff;
  border: none;
  padding: 1.5rem 2.5rem;
  border-radius: 0;
  outline: none;
  cursor: pointer;
  text-decoration: none; }

.full-button {
  width: 100%;
  height: 6rem; }

.disabled-button,
.button:disabled,
.full-button:disabled {
  background-color: #71768b;
  color: #fff;
  border: none; }

.modal .modal-dialog {
  max-width: 40rem; }

.sm__indicator-separator {
  display: none; }

input[type='checkbox'] {
  -webkit-appearance: none;
  background-color: transparent;
  outline: none; }
