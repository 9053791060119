.not-found-screen {
  height: 100vh;
  padding-top: 20vh;
  display: flex;
  max-width: 45rem;
  margin: auto;
  background-color: #fff; }
  .not-found-screen .not-found-container {
    display: flex;
    position: relative;
    flex-direction: column;
    text-align: center;
    padding: 0 4rem; }
  .not-found-screen .title {
    color: #531362;
    font-size: 16rem;
    font-weight: 700; }
  .not-found-screen .subtitle {
    color: #531362;
    font-size: 2.4rem;
    font-weight: 600; }
  .not-found-screen .text {
    color: #444444;
    font-size: 1.4rem;
    line-height: 2.4rem;
    padding-top: 2rem;
    padding-bottom: 3rem; }

@media (max-width: 768px) {
  .not-found-screen {
    padding-left: 2rem;
    padding-right: 2rem;
    text-align: center; }
    .not-found-screen .not-found-container {
      align-items: center;
      padding: 0 1rem; } }
