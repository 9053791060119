.notification {
  position: fixed;
  top: -15rem;
  left: 0;
  z-index: 1100;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 50px);
  border-radius: 5px;
  margin: 1rem;
  transition: top 0.2s 0s ease-in;
  padding: 1rem; }
  .notification.show-notification {
    top: 0; }
  .notification__content {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .notification__icon {
    width: 1.6rem;
    height: 1.6rem;
    flex-basis: 1.6rem; }
  .notification__message {
    color: #fff;
    font-size: 1.4rem;
    margin-left: 1rem;
    flex-basis: 100%; }
  .notification__close-button {
    background-color: transparent;
    border: none;
    outline: 0;
    display: inline-block;
    color: #fff; }
  .notification--error {
    background-color: #a02e44; }
  .notification--success {
    background-color: #8cc152; }
  .notification--info {
    background-color: #26a5da; }
