.success-modal {
  padding: 2rem; }
  .success-modal .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .success-modal .modal-content .success-image-container {
      display: flex;
      justify-content: center;
      padding: 4rem 0 2rem; }
    .success-modal .modal-content .message {
      padding: 2rem 0;
      font-size: 1.6rem;
      line-height: 2.4rem;
      text-align: center; }
    .success-modal .modal-content .dismiss-button {
      background-color: #531362;
      color: #fff;
      font-size: 1.4rem;
      width: 100%;
      height: 6rem;
      padding: initial;
      margin: 2rem 0; }
