.section2-wrapper {
  max-width: 45rem;
  background-color: #fff;
  margin: auto;
  padding-bottom: 40px; }
  .section2-wrapper .sign-button {
    background-color: #6939b7;
    width: calc(100% - 60px);
    height: 44px;
    margin-left: 30px;
    border-radius: 30px;
    font-family: Work Sans, sans-serif;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center; }
    .section2-wrapper .sign-button:disabled {
      background-color: #dddddd;
      color: #fff; }

.section2 {
  padding: 25px;
  color: black;
  display: flex;
  flex-direction: column;
  background-color: white;
  font-family: Lato, sans-serif;
  font-size: 1.4rem;
  line-height: 1.8rem; }
  .section2 .representative-box {
    display: flex;
    flex-direction: column;
    padding-top: 1rem; }
  .section2 .title {
    color: #5870d1;
    font-size: 2.2rem;
    font-family: Comfortaa, sans-serif;
    font-weight: 800;
    line-height: 3rem;
    text-align: center;
    padding: 1rem 0; }
  .section2 .title-small {
    font-size: 1.8rem; }
  .section2 .text {
    padding: 10px 0; }
  .section2 .heading {
    padding-top: 15px;
    padding-bottom: 10px;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2rem;
    color: black; }
  .section2 .heading-small {
    padding-top: 40px;
    font-weight: 700;
    color: black; }
  .section2 .subtitle {
    padding: 1rem 0;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 2.1rem;
    color: #71768b; }
  .section2 a {
    color: #6939b7;
    font-weight: 700;
    text-decoration: underline; }
  .section2 .list-buttons-container {
    display: flex;
    padding-top: 10px; }
    .section2 .list-buttons-container .list-button {
      flex: 1;
      height: 4.4rem;
      border: 1px solid #00a698;
      color: #00a698;
      display: flex;
      align-items: center;
      justify-content: center; }
      .section2 .list-buttons-container .list-button:hover {
        cursor: pointer; }
      .section2 .list-buttons-container .list-button.selected {
        background-color: #00a698;
        color: #fff; }
        .section2 .list-buttons-container .list-button.selected:hover {
          cursor: initial; }
      .section2 .list-buttons-container .list-button.button-blue {
        border-color: #5870d1;
        color: #5870d1; }
        .section2 .list-buttons-container .list-button.button-blue.selected {
          background-color: #5870d1;
          color: #fff; }
    .section2 .list-buttons-container .space-between {
      width: 2rem; }
  .section2 .form ::placeholder {
    color: #717579; }
  .section2 .form .MuiTextField-root {
    display: block; }
  .section2 .form .MuiInput-formControl {
    width: 100%;
    display: block; }
  .section2 .form .states-dropdown .MuiFormControl-root {
    margin-top: 16px; }
  .section2 .form .MuiSelect-select {
    font-size: 1.4rem;
    line-height: 1.6rem;
    color: #000;
    padding-right: 4px;
    padding-bottom: 10px; }
    .section2 .form .MuiSelect-select:focus {
      background-color: #fff; }
  .section2 .form .MuiInputLabel-formControl {
    font-size: 1.4rem !important;
    color: #000 !important; }
  .section2 .form .MuiInputLabel-root {
    color: #71768b !important; }
  .section2 .form .MuiInputLabel-shrink.Mui-focused {
    color: #3f51b5 !important; }
  .section2 .form input:disabled {
    background-color: rgba(0, 0, 0, 0.05); }
  .section2 .form input {
    font-size: 1.4rem;
    padding-top: 2.5rem;
    width: 100% !important;
    color: #000; }
  .section2 .form .first-name .MuiInputLabel-animated {
    letter-spacing: 0.08px; }
  .section2 .form .agree-acknowledgment,
  .section2 .form .review-acknowledgment {
    display: flex;
    padding: 3rem 0;
    width: 100%; }
    .section2 .form .agree-acknowledgment .agree-checkbox,
    .section2 .form .review-acknowledgment .agree-checkbox {
      width: 24px !important;
      height: 24px;
      border: 2px solid #6939b7;
      border-radius: 5px;
      position: relative;
      padding-top: 0; }
      .section2 .form .agree-acknowledgment .agree-checkbox:checked,
      .section2 .form .review-acknowledgment .agree-checkbox:checked {
        border: none;
        height: 1px; }
      .section2 .form .agree-acknowledgment .agree-checkbox:disabled,
      .section2 .form .review-acknowledgment .agree-checkbox:disabled {
        border: 1px solid #dddddd; }
      .section2 .form .agree-acknowledgment .agree-checkbox:checked:before,
      .section2 .form .review-acknowledgment .agree-checkbox:checked:before {
        content: ' ';
        position: absolute;
        top: -14px;
        left: 0;
        display: inline-block;
        width: 24px;
        height: 24px;
        border: none;
        border-radius: 5px;
        background: #6939b7 url("./../../assets/images/checkbox-mark.svg") center center/70% 70% no-repeat; }
    .section2 .form .agree-acknowledgment .agree-text,
    .section2 .form .review-acknowledgment .agree-text {
      padding-left: 1rem;
      margin-top: -0.1rem; }
  .section2 .form .review-acknowledgment {
    padding-bottom: 0;
    padding-top: 25px; }
  .section2 .form .text-pre-signature {
    font-weight: 600;
    text-align: center; }
  .section2 .form .subtitle {
    padding-top: 4rem;
    padding-bottom: 0;
    margin-bottom: -1rem; }
  .section2 .form .date-field label {
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left; }
  .section2 .space-between {
    width: 2rem; }
  .section2 .document-section {
    padding-bottom: 10px; }
    .section2 .document-section .upload-button {
      width: 100%;
      height: 44px;
      border: 1px solid #ba6e6e;
      color: #ba6e6e;
      font-weight: 700;
      line-height: 2.4rem;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px; }
      .section2 .document-section .upload-button img {
        padding-right: 5px;
        margin-top: -2px; }
    .section2 .document-section .upload-completed-button {
      border-color: #57ac94;
      color: #57ac94; }
    .section2 .document-section .upload-note {
      color: #ba6e6e;
      padding-top: 5px;
      font-weight: 600; }
  .section2 .review-warning .MuiInput-input {
    color: #a02e44 !important; }
    .section2 .review-warning .MuiInput-input option {
      color: #717579 !important; }
